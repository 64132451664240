<template>
  <div>
    <v-snackbar
      v-model="snackbar.opened"
      :bottom="snackbar.y === 'bottom'"
      :color="snackbar.color"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.opened = false"
          >Ok</v-btn
        >
      </template>
    </v-snackbar>
    <v-dialog v-model="deleteDialog" persistent max-width="290">
      <v-card elevation="0">
        <v-card-title class="headline"> Excluir Registro </v-card-title>
        <v-card-text
          >Confirma a Exclusão Permanentemente do Registro? Após Excluir, NÃO
          Será Possível REVERTER</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="deleteDialog = false">
            CANCELAR
          </v-btn>
          <v-btn color="red" text @click="deleteItem"> EXCLUIR </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-container fluid>
      <v-card elevation="0">
        <v-toolbar elevation="0">
          <v-row class="d-flex justify-space-around">
            <v-col cols="4">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
                height="40"
              ></v-text-field>
            </v-col>
            <v-spacer />
            <v-col cols="2">
              <v-btn color="primary" @click="openDialog = true">Novo</v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="members"
          :search="search"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)"
              >mdi-pencil</v-icon
            >
            <v-icon small class="mr-2" @click="deleteOpen(item.id)"
              >mdi-eraser</v-icon
            >
          </template>
        </v-data-table>
      </v-card>
    </v-container>

    <v-row justify="center">
      <v-dialog
        v-model="openDialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card elevation="0">
          <v-toolbar>
            <v-btn icon @click="closeDialog()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Cadastro de Membro</v-toolbar-title>
          </v-toolbar>

          <v-form>
            <v-card-text>
              <v-container>
                <v-tabs v-model="tab" centered dark>
                  <v-tabs-slider></v-tabs-slider>
                  <v-tab class="no-uppercase" href="#tab-1">
                    Dados Principais
                  </v-tab>
                  <v-tab class="no-uppercase" href="#tab-2">
                    Complemento
                  </v-tab>
                </v-tabs>
                <br />
                <v-tabs-items v-model="tab">
                  <v-tab-item :value="'tab-1'">
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          outlined
                          label="Nome"
                          v-model="name"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-autocomplete
                          outlined
                          :items="cells"
                          item-value="id"
                          item-text="name"
                          label="Célula"
                          v-model="cellId"
                          required
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-select
                          outlined
                          v-model="genre"
                          :items="genders"
                          item-text="text"
                          item-value="value"
                          label="Sexo"
                          required
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-text-field
                          outlined
                          v-mask="'###.###.###-##'"
                          label="CPF"
                          v-model="idDocument"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-text-field
                          outlined
                          type="date"
                          label="Data Nascimento"
                          v-model="birthDate"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-text-field
                          outlined
                          label="Data Conversão"
                          type="date"
                          v-model="conversionDate"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-text-field
                          outlined
                          label="Data Batismo"
                          type="date"
                          v-model="batismDate"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-text-field
                          outlined
                          v-mask="'(##)####-####'"
                          label="Fone"
                          v-model="phoneFix"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-text-field
                          outlined
                          v-mask="'(##)#####-####'"
                          label="Celular"
                          v-model="phoneMobile"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-text-field
                          outlined
                          label="Email"
                          v-model="email"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDialog()">
                        Fechar
                      </v-btn>
                      <v-btn
                        :loading="loadingAction"
                        color="success"
                        @click="registerOrUpdate()"
                      >
                        Salvar
                      </v-btn>
                    </v-card-actions>
                  </v-tab-item>
                  <v-tab-item :value="'tab-2'">
                    <v-row>
                      <v-col cols="12" sm="4">
                        <v-select
                          outlined
                          v-model="maritalStatus"
                          :items="['Casado(a)', 'Solteiro(a)']"
                          label="Estado Cívil"
                          required
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="8">
                        <v-text-field
                          outlined
                          label="Conjuge"
                          v-model="conjuge"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-text-field
                          outlined
                          v-mask="'#####-###'"
                          label="CEP"
                          v-model="zipCode"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          outlined
                          label="Logradouro"
                          v-model="street"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2">
                        <v-text-field
                          outlined
                          label="Número"
                          v-model="number"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-text-field
                          outlined
                          label="Complemento"
                          v-model="complement"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-text-field
                          outlined
                          label="Bairro"
                          v-model="zone"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-text-field
                          outlined
                          label="Cidade"
                          v-model="city"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2">
                        <v-text-field
                          outlined
                          label="UF"
                          v-model="state"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDialog()">
                        Fechar
                      </v-btn>
                      <v-btn
                        :loading="loadingAction"
                        color="success"
                        @click="registerOrUpdate()"
                      >
                        Salvar
                      </v-btn>
                    </v-card-actions>
                  </v-tab-item>
                </v-tabs-items>
              </v-container>
            </v-card-text>
          </v-form>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import http from "@/services/http";
export default Vue.extend({
  name: "Company",
  data: () => ({
    tab: null,
    deleteDialog: false,
    members: [],
    cells: [],
    search: "",
    loading: false,
    loadingAction: false,
    snackbar: {
      color: "",
      mode: "",
      opened: false,
      text: "",
      timeout: 6000,
      x: null,
      y: "top",
    },
    id: "",
    name: "",
    cellId: null,
    genre: "",
    birthDate: null,
    batismDate: null,
    conversionDate: null,
    street: "",
    zone: "",
    complement: "",
    number: "",
    city: "",
    state: "",
    zipCode: "",
    zone: "",
    phoneFix: "",
    state: "",
    idDocument: "",
    registerState: "",
    phoneMobile: "",
    email: "",
    observations: "",
    maritalStatus: "",
    conjuge: "",
    profession: "",
    salary: "",
    referenceAddress: "",
    openDialog: false,
    genders: [
      {
        value: "F",
        text: "Feminino",
      },
      {
        value: "M",
        text: "Masculino",
      },
      {
        value: "N",
        text: "Não Informado",
      },
    ],
    headers: [
      {
        text: "Nome",
        align: "start",
        value: "name",
        filtering: true,
      },
      {
        text: "Fone",
        align: "start",
        value: "phoneMobile",
        filtering: true,
      },
      {
        text: "Célula",
        align: "start",
        value: "cell.name",
        filtering: true,
      },
      { text: "Ações", value: "actions" },
    ],
  }),
  methods: {
    deleteOpen(itemId) {
      this.id = itemId;
      this.deleteDialog = true;
    },
    deleteItem() {
      http.delete(`members/${this.id}`).then(
        () => {
          this.snackbar.color = "green";
          this.snackbar.text = "Registro Excluido com Sucesso!";
          this.snackbar.opened = true;
          this.deleteDialog = false;
          this.getItems();
        },
        () => {
          this.snackbar.color = "red";
          this.snackbar.text = "Erro ao Excluir Registro. Tente Novamente!";
          this.snackbar.opened = true;
        }
      );
    },
    getItems() {
      this.loading = true;
      http.get("members").then((data) => {
        this.members = data.data;
        this.loading = false;
      });
    },
    getCells() {
      this.loading = true;
      http.get("cells").then((data) => {
        this.cells = data.data;
        this.loading = false;
      });
    },
    registerOrUpdate() {
      this.loadingAction = true;
      const payload = {
        name: this.name,
        cellId: this.cellId,
        idDocument: this.idDocument,
        genre: this.genre,
        phoneFix: this.phoneFix,
        phoneMobile: this.phoneMobile,
        email: this.email,
        street: this.street,
        zone: this.zone,
        zipCode: this.zipCode,
        city: this.city,
        state: this.state,
        birthDate: this.birthDate,
        complement: this.complement,
        number: this.number,
        registerState: this.registerState,
        observations: this.observations,
        referenceAddress: this.referenceAddress,
        maritalStatus: this.maritalStatus,
        conjuge: this.conjuge,
        conversionDate: this.conversionDate,
        batismDate: this.batismDate,
        congregationId: localStorage.getItem("congregationId"),
      };
      if (this.id) {
        http.put(`members/${this.id}`, payload).then(
          () => {
            this.snackbar.color = "green";
            this.snackbar.text = "Registro Atualizado com Sucesso!";
            this.snackbar.opened = true;
            this.loadingAction = false;
            this.closeDialog();
          },
          () => {
            this.snackbar.color = "red";
            this.snackbar.text = "Erro ao Inserir Atualizar. Tente Novamente!";
            this.snackbar.opened = true;
            this.loadingAction = false;
          }
        );
      } else {
        http.post("members", payload).then(
          () => {
            this.snackbar.color = "green";
            this.snackbar.text = "Registro Inserido com Sucesso!";
            this.snackbar.opened = true;
            this.loadingAction = false;
            this.closeDialog();
          },
          () => {
            this.snackbar.color = "red";
            this.snackbar.text = "Erro ao Inserir Registro. Tente Novamente!";
            this.snackbar.opened = true;
            this.loadingAction = false;
          }
        );
      }
    },
    closeDialog() {
      this.openDialog = false;
      this.tab = null;
      this.name = "";
      this.maritalStatus = null;
      this.batismDate = null;
      this.conversionDate = null;
      this.conjuge = null;
      this.cellId = null;
      this.idDocument = "";
      this.genre = null;
      this.registerState = "";
      this.street = "";
      this.zone = "";
      this.city = "";
      this.zipCode = "";
      this.birthDate = "";
      this.state = "";
      this.observations = "";
      this.id = "";
      this.phoneFix = "";
      this.email = "";
      this.phoneMobile = "";
      this.complement = "";
      this.number = "";
      this.referenceAddress = "";
      this.getItems();
    },
    editItem(item) {
      this.name = item.name;
      this.maritalStatus = item.maritalStatus;
      this.conjuge = item.conjuge;
      this.cellId = item.cellId;
      this.fantasyName = item.fantasyName;
      this.idDocument = item.idDocument;
      this.registerState = item.registerState;
      this.genre = item.genre;
      this.street = item.street;
      this.zone = item.zone;
      this.city = item.city;
      this.state = item.state;
      this.observations = item.observations;
      this.id = item.id;
      this.phoneFix = item.phoneFix;
      this.email = item.email;
      this.phoneMobile = item.phoneMobile;
      this.zipCode = item.zipCode;
      this.birthDate = item.birthDate;
      this.complement = item.complement;
      this.number = item.number;
      this.batismDate = item.batismDate;
      this.conversionDate = item.conversionDate;
      this.referenceAddress = item.referenceAddress;
      this.openDialog = true;
    },
  },
  mounted() {
    this.getItems();
    this.getCells();
  },
});
</script>